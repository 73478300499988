import { OrderTypeEnum, PerpsProvider } from '@kwenta/sdk/types'
import type BaseGridLayout from 'react-grid-layout'

import { FetchStatus } from './types'

export const ZERO_STATE_TRADE_INPUTS = {
	nativeSize: '',
	susdSize: '',
	orderType: OrderTypeEnum.MARKET,
	orderPrice: {
		price: undefined,
		invalidLabel: undefined,
	},
}

export const ZERO_STATE_ISOLATED_ACCOUNT = {
	position: undefined,
	trades: [],
	marketMarginTransfers: [],
	positions: [],
	delayedOrders: [],
	positionHistory: [],
}

export const ZERO_STATE_ACCOUNT = {
	position: undefined,
	balanceInfo: {
		freeMargin: '0',
		allowance: '0',
		keeperEthBal: '0',
		walletEthBal: '0',
		idleMarginByMarket: {},
		totalMarginByMarket: {},
		balances: { SUSD: '0', USDC: '0', DAI: '0' },
		allowances: { SUSD: '0', USDC: '0', DAI: '0' },
	},
	trades: [],
	tradesByPosition: {},
	liquidations: [],
	positions: [],
	accountTransfers: [],
	delayedOrders: [],
	conditionalOrders: [],
	positionHistory: [],
	delegates: [],
	orderHistory: [],
	pnlSnapshots: [],
}

export const ZERO_STATE_MARGIN_INFO = {
	availableMargin: '0',
	withdrawableMargin: '0',
	requiredMaintenanceMargin: '0',
	requiredInitialMargin: '0',
	maxLiquidationReward: '0',
	debt: '0',
}

const ZERO_MARGIN_SNAPSHOTS = {
	ONE_YEAR: [],
	ONE_MONTH: [],
	ONE_WEEK: [],
}

export const ZERO_STATE_CM_ACCOUNT = {
	...ZERO_STATE_ACCOUNT,
	balances: {},
	pendingAsyncOrder: undefined,
	collateralBalances: {},
	usdcBalance: '0',
	marginSnapshots: ZERO_MARGIN_SNAPSHOTS,
	marginInfo: {
		...ZERO_STATE_MARGIN_INFO,
	},
}

export const DEFAULT_QUERY_STATUS = {
	status: FetchStatus.Idle,
	error: null,
}

export const LOADING_STATUS = {
	status: FetchStatus.Loading,
	error: null,
}

export const SUCCESS_STATUS = {
	status: FetchStatus.Success,
	error: null,
}
export const KEEPER_USD_GAS_FEE = 0

export const GHOST_KEY = process.env.NEXT_PUBLIC_GHOST_KEY

export const DEFAULT_SUBACCOUNT_COUNTS = {
	[PerpsProvider.SNX_V3_BASE]: 0,
	[PerpsProvider.SNX_V2_OP]: 0,
}

const SM_LAYOUT: BaseGridLayout.Layout[] = [
	{
		i: 'market-details',
		x: 0,
		y: 0,
		w: 48,
		h: 14,
		minW: 8,
		minH: 8,
		maxH: 20,
	},
	{
		i: 'account-details',
		x: 0,
		y: 14,
		w: 48,
		h: 24,
		minW: 8,
		minH: 20,
	},
	{
		i: 'price-charts',
		x: 0,
		y: 38,
		w: 48,
		h: 48,
		minW: 16,
		minH: 45,
		moved: false,
		static: false,
	},
	{
		i: 'user-info',
		x: 0,
		y: 84,
		w: 48,
		h: 46,
		minW: 16,
		minH: 15,
		moved: false,
		static: false,
	},
]

const MD_LAYOUT: BaseGridLayout.Layout[] = [
	{
		i: 'market-details',
		w: 48,
		h: 12,
		x: 0,
		y: 4,
		minW: 16,
		minH: 4,
		maxH: 20,
	},
	{
		i: 'price-charts',
		w: 48,
		h: 50,
		x: 0,
		y: 56,
		minW: 16,
		minH: 45,
	},
	{
		i: 'user-info',
		w: 48,
		h: 22,
		x: 0,
		y: 56,
		minW: 16,
		minH: 15,
	},
]

const LG_LAYOUT_LEFT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 12,
		h: 93,
		x: 0,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 12,
		h: 60,
		x: 0,
		y: 93,
		minW: 8,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 36,
		h: 5,
		x: 12,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 36,
		h: 7,
		x: 14,
		y: 5,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 26,
		h: 81,
		x: 12,
		y: 12,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 10,
		h: 81,
		x: 38,
		y: 12,
		minW: 8,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 36,
		h: 60,
		x: 12,
		y: 93,
		minW: 16,
		minH: 15,
	},
]

const XL_LAYOUT_LEFT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 9,
		h: 80,
		x: 0,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 9,
		h: 78,
		x: 0,
		y: 80,
		minW: 8,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 39,
		h: 4,
		x: 9,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 39,
		h: 5,
		x: 9,
		y: 4,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 31,
		h: 79,
		x: 9,
		y: 9,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 8,
		h: 79,
		x: 40,
		y: 9,
		minW: 8,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 39,
		h: 70,
		x: 9,
		y: 88,
		minW: 16,
		minH: 15,
	},
]

const XXL_LAYOUT_LEFT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 7,
		h: 85,
		x: 0,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 7,
		h: 70,
		x: 0,
		y: 85,
		minW: 5,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 41,
		h: 3,
		x: 7,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 41,
		h: 4,
		x: 7,
		y: 3,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 34,
		h: 78,
		x: 7,
		y: 7,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 7,
		h: 78,
		x: 41,
		y: 7,
		minW: 5,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 41,
		h: 70,
		x: 7,
		y: 85,
		minW: 16,
		minH: 15,
	},
]

const XXXL_LAYOUT_LEFT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 4,
		h: 85,
		x: 0,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 4,
		h: 70,
		x: 0,
		y: 85,
		minW: 5,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 44,
		h: 3,
		x: 4,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 44,
		h: 4,
		x: 4,
		y: 3,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 40,
		h: 78,
		x: 4,
		y: 7,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 4,
		h: 78,
		x: 44,
		y: 7,
		minW: 5,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 44,
		h: 70,
		x: 4,
		y: 85,
		minW: 16,
		minH: 15,
	},
]

export const DEFAULT_LAYOUTS_LEFT: BaseGridLayout.Layouts = {
	xs: SM_LAYOUT,
	sm: SM_LAYOUT,
	md: MD_LAYOUT,
	lg: LG_LAYOUT_LEFT,
	xl: XL_LAYOUT_LEFT,
	xxl: XXL_LAYOUT_LEFT,
	xxxl: XXXL_LAYOUT_LEFT,
}

const LG_LAYOUT_RIGHT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 12,
		h: 93,
		x: 36,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 12,
		h: 60,
		x: 36,
		y: 93,
		minW: 8,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 36,
		h: 5,
		x: 0,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 36,
		h: 7,
		x: 0,
		y: 5,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 26,
		h: 81,
		x: 10,
		y: 12,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 10,
		h: 81,
		x: 0,
		y: 12,
		minW: 8,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 36,
		h: 60,
		x: 0,
		y: 93,
		minW: 16,
		minH: 15,
	},
]

const XL_LAYOUT_RIGHT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 9,
		h: 80,
		x: 39,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 9,
		h: 78,
		x: 39,
		y: 80,
		minW: 8,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 39,
		h: 4,
		x: 0,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 39,
		h: 5,
		x: 0,
		y: 4,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 31,
		h: 79,
		x: 8,
		y: 9,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 8,
		h: 79,
		x: 0,
		y: 9,
		minW: 8,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 39,
		h: 70,
		x: 0,
		y: 88,
		minW: 16,
		minH: 15,
	},
]

const XXL_LAYOUT_RIGHT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 7,
		h: 85,
		x: 41,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 7,
		h: 70,
		x: 41,
		y: 85,
		minW: 5,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 41,
		h: 3,
		x: 0,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 41,
		h: 4,
		x: 0,
		y: 3,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 34,
		h: 78,
		x: 7,
		y: 7,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 7,
		h: 78,
		x: 0,
		y: 7,
		minW: 5,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 41,
		h: 70,
		x: 0,
		y: 85,
		minW: 8,
		minH: 15,
	},
]

const XXXL_LAYOUT_RIGHT: BaseGridLayout.Layout[] = [
	{
		i: 'trade-panel',
		w: 4,
		h: 85,
		x: 44,
		y: 0,
		static: true,
	},
	{
		i: 'account-details',
		w: 4,
		h: 70,
		x: 44,
		y: 85,
		minW: 5,
		minH: 20,
	},
	{
		i: 'favorites',
		w: 44,
		h: 3,
		x: 0,
		y: 0,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'market-details',
		w: 44,
		h: 4,
		x: 0,
		y: 3,
		isResizable: false,
		isDraggable: false,
	},
	{
		i: 'price-charts',
		w: 40,
		h: 78,
		x: 4,
		y: 7,
		minW: 16,
		minH: 45,
	},
	{
		i: 'global-trades-history',
		w: 4,
		h: 78,
		x: 0,
		y: 7,
		minW: 5,
		minH: 8,
	},
	{
		i: 'user-info',
		w: 44,
		h: 70,
		x: 0,
		y: 85,
		minW: 16,
		minH: 15,
	},
]

export const DEFAULT_LAYOUTS_RIGHT: BaseGridLayout.Layouts = {
	xs: SM_LAYOUT,
	sm: SM_LAYOUT,
	md: MD_LAYOUT,
	lg: LG_LAYOUT_RIGHT,
	xl: XL_LAYOUT_RIGHT,
	xxl: XXL_LAYOUT_RIGHT,
	xxxl: XXXL_LAYOUT_RIGHT,
}
