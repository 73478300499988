import { useEffect, useState } from 'react'

import { BREAKPOINTS, type Breakpoint } from 'styles/media'

interface Size {
	width: number | undefined
	height: number | undefined
}

interface ReturnValue extends Size {
	lessThanWidth: (breakpoint: Breakpoint) => boolean
	greaterThanWidth: (breakpoint: Breakpoint) => boolean
	deviceType: 'mobile' | 'tablet' | 'desktop'
	breakpoint: Breakpoint
}

export default function useWindowSize(): ReturnValue {
	const [windowSize, setWindowSize] = useState<Size>({
		width: undefined,
		height: undefined,
	})

	const lessThanWidth = (breakpoint: Breakpoint) => {
		if (!windowSize?.width) return false
		const bpSize = BREAKPOINTS[breakpoint]
		return windowSize.width < bpSize
	}

	const greaterThanWidth = (breakpoint: Breakpoint) => {
		if (!windowSize?.width) return false
		const bpSize = BREAKPOINTS[breakpoint]
		return windowSize.width > bpSize
	}

	const getBreakpoint = (): Breakpoint => {
		if (greaterThanWidth('xxxl')) return 'xxxl'
		if (greaterThanWidth('xxl')) return 'xxl'
		if (greaterThanWidth('xl')) return 'xl'
		if (greaterThanWidth('lg')) return 'lg'
		if (greaterThanWidth('md')) return 'md'
		if (greaterThanWidth('sm')) return 'sm'
		return 'lg'
	}

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])
	return {
		...windowSize,
		lessThanWidth,
		greaterThanWidth,
		breakpoint: getBreakpoint(),
		deviceType: greaterThanWidth('lg') ? 'desktop' : greaterThanWidth('md') ? 'tablet' : 'mobile',
	}
}
